import React, { useState, useEffect } from "react";
import { formatDuration, intervalToDuration } from "date-fns";
import { zonedTimeToUtc, format } from "date-fns-tz";

const useCountDown = () => {
  const [nextDay, setNextDay] = useState(0);
  const [countdown, setCountDown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => setNextDay(getNextDay("wednesday")), []);

  const x = setInterval(() => {
    const counter = {
      duration: intervalToDuration({
        start: new Date(),
        end: zonedTimeToUtc(nextDay),
      }),

      getCounterDate() {
        const units = ["months", "days", "hours", "minutes", "seconds"];
        
        const dateLeft = formatDuration(this.duration, {
          format: units.slice(1, 5), //slice(0,2) returns 1 element only!!
          delimiter: ", ",
          zero:  true,
        }).split(",");
        return dateLeft.map((item) => parseInt(`${item.trim().split(" ")[0]}`));
      },
    };
    const [days, hours, minutes, seconds] = counter.getCounterDate();

    if (days === 7 && hours === 0 && minutes === 0 && seconds === 0) {
      clearInterval(x);
    }

    setCountDown((prevState) => ({
      ...prevState,
      days: (days === 7) || !days ? 0 : days,
      hours: !hours ? 0 : hours,
      minutes: !minutes ? 0 : minutes,
      seconds: !seconds ? 0 : seconds,
    }));
  }, 1000);

  return [
    countdown.days,
    countdown.hours,
    countdown.minutes,
    countdown.seconds,
  ];
};

export default useCountDown;

const getNextDay = (dayName) => {
  // The current day
  let date = new Date();
  let now = date.getDay();

  // Days of the week
  let days = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  // The index for the day you want
  let day = days.indexOf(dayName.toLowerCase());

  // Find the difference between the current day and the one you want
  // If it's the same day as today (or a negative number), jump to the next week
  let diff = day - now;
  diff = diff < 1 ? 7 + diff : diff;

  // Get the timestamp for the desired day
  let nextDayTimestamp = date.getTime() + 1000 * 60 * 60 * 24 * diff;

  // Get the next day
  return new Date(
    format(nextDayTimestamp, "yyyy-MM-dd'T'13:00:00", {
      timeZone: "Europe/Berlin",
    })
  );
};
