import * as React from 'react'
import { Helmet } from 'react-helmet'

import Button from '../components/Button'
import logoSource from '../images/logo.svg'
import standupBg from '../images/uploads/standup_bg.svg'
import workflow from '../images/uploads/Laptop_workflow.png'
import meeting from '../images/uploads/Laptop_meeting.png'
import user from '../images/people/till-carlos.png'
import Seo from '../components/SEO'
import CountDown from '../components/CountDown/CountDown'

const JoinStandUp = () => {
  return (
    <>
      <Seo title="Join a Standup" />
      <main className="w-full">
        <div className="flex flex-col items-center h-auto">
          {/* hero section */}
          <div className="w-full flex px-4 pt-16 pb-8 md:p-40 md:pb-20 flex-col items-center justify-between relative">
            <img
              src={standupBg}
              alt="Standup BG"
              srcSet=""
              className="absolute -top-20"
            />

            <h1 className="typo-h2 text-3xl sm:text-4xl  xl:text-5xl text-center font-semibold leading-relaxed">
              Weekly Live
              <span className="underline-highlight"> Demo</span>
            </h1>
            <p className="typo-small-2 text-center xl:text-lg mt-3 p-0">
              See how Pairing works. Every Wednesday, 11am CET
            </p>
          </div>

          {/* info section 1 */}
          <section className="standing-section md:flex-row flex-col-reverse ">
            <div className="w-full md:w-3/6 xl:w-160  flex flex-col ">
              <h1 className="typo-h2 text-center md:text-left mb-8 xl:text-4lg font-bold text-text_gray leading-relaxed">
                See how outsourcing works
              </h1>
              <ul className="typo-small-2 text-center md:text-left  mt-0 md:mt-2 mb-0 p-0 ">
                <li className="mb-10">
                  Join us for a daily standup. Every Wednesday we reserve spots
                  for external visitors to listen in.
                </li>
                <li>
                  After the 15 minute standup we have a quick Q&A session to
                  answer all your questions.
                </li>
              </ul>
            </div>
            <div
              className="flex items-center flex-1 m-4 "
              style={{ maxWidth: '700px' }}
            >
              <img
                src={meeting}
                className="max-w-full"
                alt="Pairing.dev logo"
              />
            </div>
          </section>

          {/* info section 2 */}
          <section className="standing-section">
            <div
              className="flex items-center flex-1 m-4 "
              style={{ maxWidth: '700px' }}
            >
              <img
                src={workflow}
                className="max-w-full"
                alt="Pairing.dev logo"
              />
            </div>
            <div className="w-full md:w-3/6 xl:w-160 flex flex-col   ">
              <h1 className="typo-h2 text-center md:text-left mb-8 xl:text-4lg font-bold text-text_gray leading-relaxed">
                No preparation needed
              </h1>

              <ul className="typo-small-2 text-center md:text-left mt-2 mb-0 p-0">
                <li className="mb-10">
                  You can just listen and see how we run our Scrum process.
                </li>
                <li className="mb-10">
                  You can expect to spend at least 15 minutes on the call. We
                  will introduce you, so it’s a seamless experience.
                </li>
                <li>
                  After the call we will stay in for private questions from you.
                </li>
              </ul>
            </div>
          </section>

          {/* Countdown */}
          <section className="flex flex-col mx-4 my-8  md:my-20 w-full relative">
            <img
              src={standupBg}
              alt="Standup Bg"
              srcSet=""
              className="absolute -top-20"
            />
            <div className="flex flex-col items-center mx-4  md:mx-16 2xl:mx-auto max-w-max-w-screen-2xl">
              <div className="w-full flex flex-col items-center justify-between py-16">
                <h1 className="typo-h2 text-center md:text-left  font-bold text-text_gray leading-relaxed">
                  Every Wednesday at 1pm CET
                </h1>
                <p className="font-display font-medium text-2xl">
                  Our next demo starts in
                </p>
              </div>
              <CountDown />
            </div>
          </section>

          {/* Calendly */}
          <section className="flex flex-col justify-center m-8 md:m-0 md:mt-0 w-full max-w-5xl">
            <span className="underline-highlight w-28 m-auto mb-7 h-8"></span>
            <h1 className="typo-h2 font-semibold text-center leading-relaxed">
              Save your spot now:
            </h1>
            <div className="w-11/12 md:w-full m-auto flex flex-col items-center justify-between pt-16">
              {/* <InlineWidget
                url="https://calendly.com/shepherd01/15min"
                styles={{ height: "1000px", width: "100%" }}
              /> */}
              <Helmet>
                <script
                  type="text/javascript"
                  src="https://asset-tidycal.b-cdn.net//js/embed.js"
                  async
                ></script>
              </Helmet>
              <div
                id="tidycal-embed"
                data-path="tillcarlos/pairing-scrum-standup-gues"
              ></div>

              {/* <div
                className="calendly-inline-widget w-full h-[64rem] md:h-[50rem] min-w-[320px] min-h-[630px]"
                data-url="https://calendly.com/pairing/15min"
                style={{ minHeight: '630px', minWidth: '320px' }}
              ></div> */}
            </div>
          </section>
        </div>
      </main>
    </>
  )
}

export default JoinStandUp
